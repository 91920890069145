import AuthGuard from "./modules/uko-react-main/components/authentication/AuthGuard";
import GuestGuard from "./modules/uko-react-main/components/authentication/GuestGuard";
import DashboardLayout from "./modules/uko-react-main/components/Layouts/DashboardLayout";
import LoadingScreen from "./modules/uko-react-main/components/LoadingScreen";
import React, {FC, lazy, LazyExoticComponent, ReactElement, Suspense, useEffect} from "react";
import {Navigate, Outlet} from "react-router-dom";
import NavigationBar from "./features/components/NavigationBar";
import LazyComponent from "./features/components/LazyComponent";
import {Grid} from "@mui/material";
import UserInfoProvider from "./features/components/UserInfoProvider";
import UserNotFoundPage from "./features/pages/profile/UserNotFoundPage";
import ErrorHandler from "./features/components/ErrorHandler";
import useAuth from "./features/hooks/useAuth";
import CurrentUserInfoProvider from "./features/components/CurrentUserInfoProvider";

const Profile = LazyComponent(() => import("./features/pages/profile"));
const Main = LazyComponent(() => import("./features/pages/main"));
const About = LazyComponent(() => import("./features/pages/about"));
// authentication pages
// const Login = Loadable(lazy(() => import("./modules/uko-react-main/pages/authentication/Login")));
const Login = LazyComponent(() => import("./features/pages/login/index"));
// const Register = Loadable(
//   lazy(() => import("./modules/uko-react-main/pages/authentication/Register"))
// );
const ForgetPassword = LazyComponent(() => import("./modules/uko-react-main/pages/authentication/ForgetPassword"));

// Dashboard pages
const DashboardSaaS = LazyComponent((() => import("./modules/uko-react-main/pages/dashboards/SaaS")));

// user profile
const UserProfile = LazyComponent((() => import("./modules/uko-react-main/pages/UserProfile")));

// user management
const UserList = LazyComponent(
  (() => import("./modules/uko-react-main/pages/userManagement/UserList"))
);
const UserGrid = LazyComponent(
  (() => import("./modules/uko-react-main/pages/userManagement/UserGrid"))
);
const AddNewUser = LazyComponent(
  (() => import("./modules/uko-react-main/pages/userManagement/AddNewUser"))
);

// error
const Error = LazyComponent((() => import("./modules/uko-react-main/pages/404")));

// routes
const routes = [
  {
    path: "/",
    // element: <Navigate to="login" />,
    element: (
        <ErrorHandler>
            <CurrentUserInfoProvider>
              <Suspense fallback={<LoadingScreen/>}>
                <NavigationBar/>
              </Suspense>
            </CurrentUserInfoProvider>

            <Suspense fallback={<LoadingScreen/>}>
              <Outlet/>
            </Suspense>
        </ErrorHandler>
    ),
    children: [
      {
        path: "",
        element: (
            <CurrentUserInfoProvider>
              <Main/>
            </CurrentUserInfoProvider>
        ),
        children: [

        ]
      },
      {
        path: "about",
        element: (<About/>),
      },
      {
        path: "profile",
        element: (
            <>
              <Outlet/>
            </>
        ),
        children: [
          {
            path: ":uid",
            element: (
                <Profile/>
            )
          }
        ]
      },
    ]
  },

  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  // {
  //   path: "register",
  //   element: (
  //     <GuestGuard>
  //       <Register />
  //     </GuestGuard>
  //   ),
  // },
  {
    path: "forget-password",
    element: (
      <GuestGuard>
        <ForgetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "admin/dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <DashboardSaaS />,
      },
      {
        path: "user-profile",
        element: <UserProfile />,
      },

      {
        path: "user-list",
        element: <UserList />,
      },
      {
        path: "user-grid",
        element: <UserGrid />,
      },
      {
        path: "add-user",
        element: <AddNewUser />,
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];

export default routes;
