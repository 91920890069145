import React, {ReactElement} from "react";
import {Typography} from "@mui/material";

type ErrorHandlerProps = {
    hasError?: boolean,
    fallback?: ReactElement,
    children?: any,
}
type ErrorHandlerState = {
    hasError: boolean,
    error?: any
}
export default class extends React.Component<ErrorHandlerProps, ErrorHandlerState> {
    constructor(props: ErrorHandlerProps) {
        super(props);

        // to keep track of when an error occurs
        // and the error itself
        this.state = {
            hasError: false,
            error: undefined
        };
    }

    // update the component state when an error occurs
    static getDerivedStateFromError(error: Error) {
        // specify that the error boundary has caught an error
        return {
            hasError: true,
            error: error
        };
    }

    // defines what to do when an error gets caught
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        // log the error
        console.log("Error caught!");
        console.error(error);
        console.error(errorInfo);

        this.setState({
            hasError: true,
            error: errorInfo
        })
        // record the error in an APM tool...
    }

    render() {
        // if an error occurred
        if (this.state.hasError) {
            if (this.props.fallback) {
                return this.props.fallback;
            }
            return (
                <Typography>
                    Error
                </Typography>
            )
        }

        // default behavior
        return this.props.children;
    }
}