import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SettingsProvider from "./modules/uko-react-main/contexts/SettingsContext";
import TitleContextProvider from "./modules/uko-react-main/contexts/TitleContext";
import {BrowserRouter} from "react-router-dom";
import AuthProvider from "./features/components/AuthProvider";

function FirestoreApi() {
    return (
        <>
            <script defer src="/__/firebase/10.0.0/firebase-app-compat.js"></script>

            <script defer src="/__/firebase/10.0.0/firebase-auth-compat.js"></script>
            <script defer src="/__/firebase/10.0.0/firebase-database-compat.js"></script>
            <script defer src="/__/firebase/10.0.0/firebase-firestore-compat.js"></script>
            {/*<script defer src="/__/firebase/10.0.0/firebase-functions-compat.js"></script>*/}
            {/*<script defer src="/__/firebase/10.0.0/firebase-messaging-compat.js"></script>*/}
            <script defer src="/__/firebase/10.0.0/firebase-storage-compat.js"></script>
            {/*<script defer src="/__/firebase/10.0.0/firebase-analytics-compat.js"></script>*/}
            {/*<script defer src="/__/firebase/10.0.0/firebase-remote-config-compat.js"></script>*/}
            {/*<script defer src="/__/firebase/10.0.0/firebase-performance-compat.js"></script>*/}

            <script defer src="/__/firebase/init.js?useEmulator=true"></script>
        </>
    )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <FirestoreApi/>
      <AuthProvider>
          <SettingsProvider>
              <TitleContextProvider>
                  <BrowserRouter>
                      <App />
                  </BrowserRouter>
              </TitleContextProvider>
          </SettingsProvider>
      </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
