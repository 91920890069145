import React, {createRef, RefObject, Suspense} from 'react';
import logo from './logo.svg';
import './App.css';
import {
    Route,
    Routes,
    BrowserRouter,
    useLocation,
    useNavigate,
    useNavigation,
    Navigate,
    createBrowserRouter, RouteObject, useRoutes
} from "react-router-dom";
import Error from "./features/pages/error";
import Main from "./features/pages/main";
import {createTheme, CssBaseline, responsiveFontSizes, ThemeProvider} from "@mui/material";
import Admin from "./features/pages/admin";
import routes from "./routes";
import {Toaster} from "react-hot-toast";
import {StyledEngineProvider} from "@mui/material/styles";
import {ukoTheme} from "./modules/uko-react-main/theme";
import UserInfoProvider from "./features/components/UserInfoProvider";
import LoadingScreen from "./modules/uko-react-main/components/LoadingScreen";

function Router() {
  const location = useLocation();
  const allPages = useRoutes(routes);

  return (
      <>
        <Routes location={location}>
          {routes.map(t => {
            return (
                <Route key={t.path} path={t.path} element={t.element}/>
            )
          })}
        </Routes>
      </>
  )
}

function App() {
    const appTheme = responsiveFontSizes(createTheme({
        palette: {
            mode: "dark",
        },
        typography: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: 12,
        },
    }));
    const allPages = useRoutes(routes);
    const toasterOptions = {
        style: {
            fontWeight: 500,
            fontFamily: "'Montserrat', sans-serif",
        },
    };

    return (
      // <div className="App">
      //   <CssBaseline enableColorScheme/>
      //   <BrowserRouter>
      //     <Router/>
      //   </BrowserRouter>
      // </div><

        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={appTheme}>
                <CssBaseline />
                <Toaster toastOptions={toasterOptions} />
                {allPages}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}


// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
