import {userAuth} from "./firebaseApp";
import * as Auth from "firebase/auth";
import {UserInfo} from "./firestore/types";
import userCollection from "./firestore/userCollection";

export function GetUID() {
    return userAuth.currentUser?.uid;
}
export function HasLoggedIn() {
    return userAuth.currentUser != null;
}
export async function LoginWithEmail(email: string, password: string) {
    const credential = await Auth.signInWithEmailAndPassword(userAuth, email, password);
    return credential;
}
export async function Logout() {
    await Auth.signOut(userAuth);
}

export default {
    GetUID,
    HasLoggedIn,
    LoginWithEmail,
    Logout,
}