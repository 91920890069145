export type SuspenseStatusType = 'pending' | 'success' | 'error';

// https://blog.logrocket.com/react-suspense-data-fetching/
// 보아하니 상위 hierarchy 에 Suspense 컴포넌트가 존재하고, 하위 컴포넌트에서
// 특정 키워드 또는 throw 를 하면 캐치해서 처리하는 듯

export type Suspender<T> = {
    getStatus: () => SuspenseStatusType,
    read: () => T
}
function wrapper<T>(p: Promise<T | undefined | null>): Suspender<T> {
    let status: SuspenseStatusType = 'pending';
    let response: T | any;

    const suspender = p.then(
        (res) => {
            if (res === undefined || res === null) {
                status = 'pending';
                return;
            }
            status = 'success';
            response = res!;
            // console.log('success');
        },
        (err) => {
            status = 'error';
            response = err;
            // console.log('error');
        })
        .catch(err => {
            // console.log("catch err");
            status = 'error';
            response = err;
        });

    const getStatus = () => status;
    const read = () => {
        // console.log(getStatus());

        switch (getStatus()) {
            case 'pending':
                throw suspender;
            case 'error':
                throw response!;
            default:
                return response!;
        }
    }

    return {
        getStatus,
        read
    };
}

// const resultValue = () => wrapper;

export default wrapper;