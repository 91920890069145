import {useEffect, useState} from "react";
import {Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import * as User from "../apis/User";
import toast from "react-hot-toast";
import {Logout} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import useUserInfo from "../hooks/useUserInfo";
import useAuth from "../hooks/useAuth";

type AccountButtonProps = {
}

function AccountButton(props?: AccountButtonProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const userInfoState = useUserInfoState();
    const navigate = useNavigate();
    const auth = useAuth();
    const userInfo = useUserInfo();

    useEffect(() => {

    }, [userInfo])

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // if (!auth.signIn || userInfoState === undefined) {
    //     return (
    //         <>
    //             ERROR
    //         </>
    //     )
    // }
    // const userInfo = userInfoState.read();

    if (!auth.signIn) {
        return (
            <>
                <IconButton onClick={handleClick} size={'small'}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup={'true'}
                            aria-expanded={open? true:undefined}>
                    <Avatar alt={'A'} sx={{ width: 30, height: 30}}>
                        {/*<AccountCircle fontSize={"inherit"}/>*/}
                    </Avatar>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    slotProps={{
                        paper: {
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={() => {
                        handleClose();
                        navigate(`/profile/${User.GetUID()}`);
                        // props.navigate('/my-page');
                    }}>
                        <Avatar />
                        <Typography fontSize={15}>
                            {/*{User.GetUsername()}*/}
                            Unknown
                        </Typography>
                    </MenuItem>
                    <MenuItem disabled>
                        Login Required
                    </MenuItem>
                </Menu>
            </>
        )
    }

    return (
        <>
            <IconButton onClick={handleClick} size={'small'}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup={'true'}
                        aria-expanded={open? true:undefined}>
                <Avatar alt={'A'} sx={{ width: 30, height: 30}}>
                    {/*<AccountCircle fontSize={"inherit"}/>*/}
                </Avatar>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    navigate(`/profile/${User.GetUID()}`);
                    // props.navigate('/my-page');
                }}>
                    <Avatar />
                    <Typography fontSize={15}>
                        {/*{User.GetUsername()}*/}
                        {userInfo.username}
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => {
                    window.open('https://database.krafe.net/drives');
                }}>
                    <ListItemIcon>
                        <FolderIcon fontSize={"small"}/>
                    </ListItemIcon>
                    <Typography fontSize={12}>FileBrowser(old)</Typography>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    // props.navigate('/database');
                }}>
                    <ListItemIcon>
                        <FolderIcon fontSize={"small"}/>
                    </ListItemIcon>
                    <Typography fontSize={12}>Database</Typography>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={() => {
                    handleClose();

                    User.Logout()
                        .then(() => {
                            toast.success("Logout success");
                            // props?.onLogout();
                        })
                        .catch((err) => {
                            toast.error(err);
                        });
                }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    <Typography fontSize={12}>Logout</Typography>
                </MenuItem>
            </Menu>
        </>
    )
}

export default AccountButton;