import useAuth from "../hooks/useAuth";
import React, {ReactElement, useEffect} from "react";
import UserInfoProvider from "./UserInfoProvider";

type CurrentUserInfoProviderProps = {
    children: ReactElement
}
export default function CurrentUserInfoProvider(props: CurrentUserInfoProviderProps) {
    const auth = useAuth();

    useEffect(() => {
    }, [auth.signIn])

    return (
        <UserInfoProvider uid={auth.user?.uid}>
            {props.children}
        </UserInfoProvider>
    )
}