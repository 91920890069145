import {useNavigate} from "react-router-dom";
import {
    alpha,
    AppBar, Box, CircularProgress,
    CssBaseline,
    Grid,
    IconButton, InputBase,
    Link,
    makeStyles,
    Paper,
    styled,
    Theme,
    Toolbar,
    Typography
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import React, {Suspense, useEffect, useState} from "react";
import useAuth from "../hooks/useAuth";
import AccountButton from "./AccountButton";
import LoginButton from "./LoginButton";
import LoadingScreen from "../../modules/uko-react-main/components/LoadingScreen";
import {Downloading} from "@mui/icons-material";
import * as User from "../apis/User";
import ProgressCircle from "./ProgressCircle";

const StyledLogo = styled(Link)(({theme}) => ({
    // flexGrow: "1",
    cursor: "pointer",

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end'

}));
const StyledNavLinks = styled('div')(({theme}) => ({
    marginLeft: theme.spacing(10),
    display: "flex",
    alignItems: 'center',
}));
const StyledLink = styled(Link)(({theme}) => ({
    component: 'button',
    textDecoration: "none",
    color: "white",
    fontSize: "15px",

    display: "flex",
    justifyContent: 'center',
    lineHeight: '100%',

    // textAlign: "right",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),

    // transition: 'all .25s',
    // "&:hover": {
    //     fontSize: "18px",
    //     // color: "yellow",
    //     // borderBottom: "1px solid white"
    // }
}));

const StyledSearch = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    alignItems: 'center',
    justifyContent: 'center',
}));

function NavigationBar(props: any) {
    const navigate = useNavigate();
    const auth = useAuth();

    useEffect(() => {
        console.log('refresh');
    }, [auth.signIn]);

    // let userElement;
    // if (!auth.signIn) {
    //     userElement = (
    //         <LoginButton/>
    //     );
    //     // console.log('has not log in');
    // } else
    // {
    //     // console.log('has logged in');
    //     userElement = (
    //         <Suspense fallback={<ProgressCircle/>}>
    //             <AccountButton/>
    //         </Suspense>
    //     );
    // }

    return (
        <AppBar position={"static"}>
            <CssBaseline/>

            <Paper elevation={10}>
                <Toolbar sx={
                    {
                        backgroundColor: "#2D2D2D",
                        justifyContent: 'space-between'
                    }
                }>
                    <StyledLogo variant={'body2'} underline={'none'}
                          onClick={() => navigate('/')}
                    >
                        {/*<a>*/}
                        {/*    <img src={'logo512.png'} width={32} height={32} style={{objectFit:'contain'}} />*/}
                        {/*</a>*/}
                        <Typography variant={"h4"} color={'white'}>
                            <b>Krafe</b>
                        </Typography>
                        <Typography lineHeight={1.65} color={'white'}>
                            <b>.net</b>
                        </Typography>
                    </StyledLogo>
                    <Box display={'flex'} flexDirection={'row'}>
                        <StyledSearch>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </StyledSearch>
                        <StyledNavLinks>
                            <StyledLink
                                // variant={'body2'}
                                onClick={() => navigate('/about')}
                            >
                                {/*<Typography>*/}
                                about
                                {/*</Typography>*/}
                            </StyledLink>

                            {/*{userElement}*/}
                            <Suspense fallback={<ProgressCircle/>}>
                                <AccountButton/>
                            </Suspense>
                            {/*<LoginButton/>*/}
                        </StyledNavLinks>
                    </Box>
                </Toolbar>
            </Paper>
        </AppBar>
    )
}

export default NavigationBar;