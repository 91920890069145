import {
    addDoc, deleteDoc, doc,
    DocumentData, DocumentReference,
    FirestoreDataConverter, getDoc,
    QueryDocumentSnapshot,
    SnapshotOptions, Timestamp, updateDoc,
    WithFieldValue
} from "firebase/firestore";
import {DbPostInfo, DbUserInfo, PostInfo, UserInfo} from "./types";
import {getCollectionRef} from "./index";

const converter: FirestoreDataConverter<DbPostInfo> = {
    toFirestore(modelObject: WithFieldValue<DbPostInfo>): WithFieldValue<DocumentData> {
        return modelObject;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>, options?: SnapshotOptions): DbPostInfo {
        return snapshot.data(options)! as DbPostInfo;
    }
}
function getColRef() {
    return getCollectionRef("posts", converter);
}

async function updatePost(uid: string | DocumentReference<DbPostInfo>, value: DbPostInfo) {
    const ref = (typeof(uid) === 'string') ? doc(getColRef(), uid) : uid;
    const newValue: DbPostInfo = {
        ...value,
        modified_time: Timestamp.fromDate(new Date())
    }
    await updateDoc(ref, newValue);
}
async function newPost(userUid: DocumentReference<DbUserInfo, DocumentData>,
                       title: string, context: string) {
    const time = Timestamp.fromDate(new Date());
    const data: DbPostInfo = {
        created_time: time,
        modified_time: time,

        created_user: userUid,
        created_user_id: userUid.id,

        title,
        context
    };
    const result = await addDoc(getColRef(), data);
    const value: PostInfo = {
        uid: result,
        exists: true,
        ...data
    }
    return value;
}
async function getPost(uid: string | DocumentReference<DbPostInfo>) {
    const ref =
        (typeof(uid) === 'string') ? doc(getColRef(), uid) : uid;
    const result = await getDoc(ref);
    const data: PostInfo = {
        uid: ref,
        exists: result.exists(),
        ...result.data()
    }
    return data;
}
async function deletePost(uid: string) {
    const ref = doc(getColRef(), uid);
    await deleteDoc(ref);
}

export default {
    getCollectionRef: getColRef,

    newPost,
    updatePost,
    getPost,
    deletePost
}