import {FC, lazy, LazyExoticComponent, Suspense} from "react";
import LoadingScreen from "../../modules/uko-react-main/components/LoadingScreen";

const loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
    (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );

export default function (factory: () => Promise<{ default: FC }>) {
    const temp = lazy(factory);

    return loadable(temp);
}