import {
    deleteDoc,
    doc,
    DocumentData,
    DocumentReference,
    FirestoreDataConverter, getDoc,
    QueryDocumentSnapshot, setDoc,
    SnapshotOptions, updateDoc,
    WithFieldValue
} from "firebase/firestore";
import {DbPostInfo, DbUserInfo, PostInfo, UserInfo} from "./types";
import {getCollectionRef} from "./index";
import postCollection from "./postCollection";
import firebase from "firebase/compat";

const userConverter: FirestoreDataConverter<DbUserInfo> = {
    toFirestore(modelObject: WithFieldValue<DbUserInfo>): WithFieldValue<DbUserInfo> {
        return modelObject;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>, options?: SnapshotOptions): DbUserInfo {
        return snapshot.data(options)! as DbUserInfo;
    }
}
function getUserCollectionRef() {
    return getCollectionRef("users", userConverter);
}

export async function getUserInfo(uid: string) {
    const ref = doc(getUserCollectionRef(), uid);

    const result = await getDoc(ref);
    const value: UserInfo = {
        uid: ref,
        exists: result.exists(),
        ...result.data()
    }
    return value;
}
export async function setUserInfo(uid: string, data: DbUserInfo) {
    const ref = doc(getUserCollectionRef(), uid);

    return await setDoc(ref, data);
}
export async function updateUserInfo(uid: string | DocumentReference<DbUserInfo>, data: DbUserInfo) {
    const ref =
        (typeof(uid) === "string") ? doc(getUserCollectionRef(), uid) : uid;

    return await updateDoc(ref, data);
}
async function createBio(uid: string | DocumentReference<DbUserInfo>, context?: string) {
    const ref = (typeof(uid) === "string") ? doc(getUserCollectionRef(), uid) : uid;

    const postResult = await postCollection.newPost(ref, "bio", context ?? "");
    await updateUserInfo(ref, {
        bio: postResult.uid
    })

    return postResult;
}
async function updateBio(uid: string | DocumentReference<DbPostInfo>, context: string) {
    await postCollection.updatePost(uid, {
        context: context
    });
}
export async function deleteUserInfo(uid: string) {
    const ref = doc(getUserCollectionRef(), uid);

    await deleteDoc(ref);
}

export default {
    getCollectionRef: getUserCollectionRef,

    getUserInfo,
    setUserInfo,
    updateUserInfo,
    deleteUserInfo,

    createBio,
    updateBio
}