import React, { Fragment, ReactNode } from "react";
import {Navigate} from "react-router-dom";

// component props interface
interface GuestGuardProps {
  children: ReactNode;
}
const GuestGuard = ({ children }: GuestGuardProps) => {
  //// UNCOMMNET BELOW CODE IF YOU WANT TO HIDE AUTH PAGES TO AUTHENTICATED USERS

    // const { isAuthenticated } = useAuth();
    // if (isAuthenticated) {
    //   return <Navigate to="/" />;
    // }

  return <Fragment>{children}</Fragment>;
};

export default GuestGuard;
