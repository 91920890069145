import {userAuth} from "../apis/firebaseApp";
import {createContext, ReactNode, useEffect, useState} from "react";
import { User } from "firebase/auth";

type AuthContextType = {
    initialized: boolean,
    user: User | null,
    signIn: boolean,
};
export const AuthContext = createContext<AuthContextType>(
{
    initialized: false,
    user: null,
    signIn: false,
});


type AuthProviderProps = {
    children: ReactNode;
};

export default function ({children}: AuthProviderProps) {
    const [initialized, setInitialized] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const [signIn, setSignIn] = useState(false);
    useEffect(() => {
        userAuth.onAuthStateChanged((user: User | null) => {
            console.log(user == null ? "User logout" : "User login");
            setUser(user);
            setSignIn(user != null);

            setInitialized(true);
        });
    }, [])

    return (
        <AuthContext.Provider value={
            {
                initialized,
                user,
                signIn,
            }
        }>
            {children}
        </AuthContext.Provider>
    )
}