import {createContext, ReactElement, ReactNode, useEffect, useState} from "react";
import LoadingScreen from "../../modules/uko-react-main/components/LoadingScreen";
import * as User from "../apis/User";
import getSuspense, {Suspender} from "../hooks/getSuspense";
import {UserInfo} from "../apis/firestore/types";
import {DocumentData, DocumentReference} from "firebase/firestore";
import userCollection from "../apis/firestore/userCollection";

type UserInfoProviderProps = {
    uid?: string,
    fallback?: ReactElement,
    children?: any
}
const defaultValue: UserInfoContext = {
    // uid: ,
    initialized: false,
    exists: false
}
export type UserInfoContext = {
    initialized: boolean,
} & UserInfo;
export const Context = createContext<UserInfoContext>(defaultValue);

export default function (props: UserInfoProviderProps) {
    const [data, setData] = useState<Suspender<UserInfo> | null | undefined>(undefined);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        let uid = props.uid;
        if (uid === undefined) {
            uid = User.GetUID();
        }
        if (uid !== undefined) {
            setInitialized(false);

            const func= getSuspense(
                userCollection.getUserInfo(uid!)
                    .then(x => {
                        setInitialized(true);
                        return x;
                    })
            );
            setData(func);
        }
        else {
            setData(null);
            setInitialized(true);
        }
    }, [props.uid])

    // console.log(`prov data ${data === undefined}`);
    if (data === undefined) {
        return props.fallback === undefined ? <LoadingScreen/> : props.fallback;
    }

    const defaultValue: UserInfo = {
        exists: false
    }
    const userInfo = data ? data.read() : defaultValue;
    return (
        <Context.Provider value={{
            // uid: props.uid!,
            // uid: userInfo.uid,
            ...userInfo,
            initialized,
        }}>
            {props.children}
        </Context.Provider>
    )
}