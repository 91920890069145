// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import {createContext, useContext} from "react";
import {DocumentData, Firestore, getFirestore, WithFieldValue} from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCj3AfKYwk7vwte5VITQfGaf6CtSBfvJmU",
    authDomain: "krafe-net.firebaseapp.com",
    projectId: "krafe-net",
    storageBucket: "krafe-net.appspot.com",
    messagingSenderId: "566585656223",
    appId: "1:566585656223:web:de3a24cc91b88ec1d573aa",
    measurementId: "G-MNJX277RFD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const userAuth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

